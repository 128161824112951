<template>
  <div class="form-group">
    <label :for="property">{{ label }}</label>
    <input
      v-model="input[property]"
      class="form-control mt-2 mb-2"
      type="datetime-local"
      @input="inputEntered"
    />
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DatePropertyInput",
  props: ["property", "profile", "label"],
  data() {
    return {
      input: {},
    };
  },
  components: {},
  computed: {},
  methods: {
    inputEntered() {
      this.$emit("inputEntered", {
        value: this.input[this.property],
        property: this.property,
      });
    },
  },
  mounted() {
    this.profile[this.property]
      ? (this.input[this.property] = moment(this.profile[this.property]).format(
          "YYYY-MM-DDTHH:mm"
        ))
      : (this.input[this.property] = null);
  },
};
</script>