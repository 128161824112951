<template>
  <div class="form-group">
    <label :for="property">{{ label }}</label>
      <ion-accordion-group>
        <ion-accordion value="lable">
          <ion-item slot="header">
            <ion-label>{{ label === 'Address' ? profile[property]['street'] : ''}}</ion-label>
          </ion-item>
          <ion-list slot="content" v-for="prop in properties" :key="prop">
            <ion-item lines="none">
              <input 
              autocomplete="off" 
              class="form-control" 
              type="text" 
              v-model="input[prop]" 
              @input="inputEntered">
            </ion-item>
          </ion-list>
        </ion-accordion>
      </ion-accordion-group>
  </div>
</template>

<script>
import UpdateProfileBase from './UpdateProfileBase.vue'
import { IonLabel, IonItem,IonList, IonAccordion, IonAccordionGroup } from "@ionic/vue"

export default {
  name:'ObjectPropertyInput',
  props: [ 'property', 'profile', 'label'],
  extends: UpdateProfileBase,
  data(){
    return {
      input: {}
    }
  },
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonAccordion,
    IonAccordionGroup,
  },
  computed: {

    properties(){
      return this.getProperties(this.profile[this.property])
    }
  },
  methods: {
    inputEntered(){
      this.$emit('inputEntered', {
        value: this.input,
        property: this.property
      })
    }
  }, 
  mounted() {
    this.profile[this.property] ? this.properties.forEach(prop => {
      this.input[prop] = this.profile[this.property][prop]
    }) : this.input = null
  }
}
</script>