<script>
import { createNamespacedHelpers } from "vuex";
const ProfileModule = createNamespacedHelpers("profile");

export default {
  name: "UpdateProfileBase",
  components: {},
  computed: {
    ...ProfileModule.mapGetters({ profileData: "selectProfile" }),
  },
  methods: {
    /**
     * get all the object properties
     */
    getProperties(object) {
      return Object.getOwnPropertyNames(object)
        .filter(
          (prop) =>
            prop !== "_id" &&
            prop !== "__v" &&
            prop !== "userId" &&
            prop !== "phoneVerified" &&
            prop !== "createdAt" &&
            prop !== "updatedAt" &&
            prop !== "metadata"
        )
        .sort((a, b)=> {
          if(this.getPropertyTypes(this.profileData[a]) === 'string' && this.getPropertyTypes(this.profileData[b]) !== 'string'){
            return -1
          }
          if(this.getPropertyTypes(this.profileData[b]) === 'string' && this.getPropertyTypes(this.profileData[a]) !== 'string'){
            return 1
          }
          if(this.getPropertyTypes(this.profileData[a]) === 'date' && this.getPropertyTypes(this.profileData[b]) === 'array'){
            return -1
          }
          if(this.getPropertyTypes(this.profileData[a]) === 'array' && this.getPropertyTypes(this.profileData[b]) === 'date'){
            return 1
          }
          if(this.getPropertyTypes(this.profileData[b]) === 'date' && this.getPropertyTypes(this.profileData[a]) === 'object'){
            return -1
          }
          if(this.getPropertyTypes(this.profileData[b]) === 'object' && this.getPropertyTypes(this.profileData[a]) === 'date'){
             return 1
          }
          if(this.getPropertyTypes(this.profileData[b]) === 'array' && this.getPropertyTypes(this.profileData[a]) === 'object'){
             return -1
          }
          if(this.getPropertyTypes(this.profileData[b]) === 'object' && this.getPropertyTypes(this.profileData[a]) === 'array'){
             return 1
          }
          if(this.getPropertyTypes(this.profileData[a]) === this.getPropertyTypes(this.profileData[b])){
            return 0
          }
        })
    },
    getPropertyTypes(property) {
      return Array.isArray(property)
        ? "array"
        : new Date(property) != "Invalid Date"
        ? "date"
        : typeof property;
    },
    getPropertyLabel(property) {
      const found = property.toString().match(/[A-Z]/);
      if (found) {
        const characters = found[0];
        property = property.replace(/[A-Z]/, ` ${characters}`);
        return this.capitalizeFirstLetter(property);
      } else {
        return this.capitalizeFirstLetter(property);
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>