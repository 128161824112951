<template>
  <div class="row">
      <div class="text-muted">
        <h6 :for="property">{{ label }}</h6>
      </div>
      <div class="array-items col-sm-12 col-md-3" v-for="(item, index) in input[this.property]" :key="index">
        <div class="input-group mb-2">
          <input class="form-control" type="text" 
          v-model="input[property][index]" @input="inputEntered">
          <div class="input-group-append">
            <button 
            @click.prevent="removeFromset(index, property)" 
            :class="application.theme === 'dark' ? 'btn btn- text-white': 'btn btn-black'"><ion-icon :icon="removeOutline"></ion-icon></button>
          </div>
        </div>
      </div>
      <div>
        <hr>
        <button class="btn">
          <span>
            <ion-icon :class="application.theme === 'dark' ? 'btn btn- text-white': 'btn btn-black'" 
            :icon="addOutline" @click.prevent="addToSet(property)"></ion-icon>
          </span>
        </button>
      </div>
    </div>
</template>

<script>
import { IonIcon } from '@ionic/vue'
import { closeOutline, addOutline, trashOutline, removeOutline } from 'ionicons/icons'
import { createNamespacedHelpers } from 'vuex'
const SettingsModule = createNamespacedHelpers('setting')

export default {
  name:'ArrayPropertyInput',
  props: [ 'property', 'profile', 'label'],
  data(){
    return {
      input: {}
    }
  },
  setup() {
    return {
      addOutline,
      closeOutline,
      trashOutline,
      removeOutline,
    }
  },
  components: {
    IonIcon,
  },
  computed: {
    ...SettingsModule.mapGetters(['selectApplicationSettings']),

    application(){
      return this.selectApplicationSettings
    }
  },
  methods: {
    addToSet(property){
      this.$emit('addOneToSet', { property })
    },
    inputEntered(){
      this.$emit('inputEntered', {
        value: this.input[this.property],
        property: this.property
      })
    },
    removeFromset(index, property){
      this.$emit('removeFromset', {index, property})
    }
  }, 
  mounted() {
    this.profile[this.property] ? this.input[this.property] = this.profile[this.property]:
    this.input[this.property] = []
  }
}
</script>
<style scoped lang="scss">
  
</style>