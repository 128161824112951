<template>
  <div class="form-group">
      <label :for="property">{{ label }}</label>
      <input v-model="input[property]" class="form-control mt-2 mb-2" type="text" @input="inputEntered">
  </div>
</template>

<script>
export default {
  name:'StringPropertyInput',
  props: [ 'property', 'profile', 'label'],
  data(){
    return {
      input: {}
    }
  },
  components: {},
  computed: {},
  methods: {
    inputEntered(){
      this.$emit('inputEntered', {
        value: this.input[this.property],
        property: this.property
      })
    }
  }, 
  mounted() {
    this.profile[this.property] ? this.input[this.property] = this.profile[this.property] :
    this.input[this.property] = null
  }
}
</script>