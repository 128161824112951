<template>
  <ion-page>
    <ion-header>
      <ion-toolbar></ion-toolbar>
    </ion-header>
    <ion-content :style="settings.bg">
      <div class="container">
        <div class="row mt-5">
          <div class="update-section col-md-12">
            <div class="form-group mb-4">
                <button
                  class="btn btn-outline-secondary btn-sm"
                  @click.prevent="savePersonal"
                >
                  Update
                </button>
            </div>
          </div>
          <div class="col-md-12 text-left">
            <div class="card" :style="settings.fg">
              <div class="card-body">
                <div class="avatar-section">
              <div class="avatar-title">
                <h6 class="text-muted">Avatar</h6>
              </div>
              <Avatar style="float: left"></Avatar>
              <div class="avatar-btn">
                <p>Click on the avatar to change it or remove it.</p>
                <!-- <ion-button fill="outline" color="success" size="small">Change</ion-button>
                <ion-button fill="outline" color="danger" size="small">Remove</ion-button> -->
              </div>
            </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-12 mb-4">
            <div class="card" :style="settings.fg">
              <div class="card-body">
                <ion-accordion-group multiple="true" expand="compact" class="mb-4" :style="settings.fg">
                  <ion-accordion value="personal" class="mb-2" :style="settings.fg">
                    <ion-item slot="header" :style="settings.fg">
                      <ion-label class=""><ion-icon :icon="personOutline"></ion-icon> Personal <br><br>
                        <small class="text-muted mt-5">First name, email, and phone...</small>
                      </ion-label>
                    </ion-item>
                    <ion-list slot="content" :style="settings.fg">
                      <ion-item lines="none" :style="settings.fg">
                        <div class="row">
                          <div
                            v-for="property in properties"
                            :key="property"
                            class="col-sm-12 col-md-3"
                          >
                            <StringPropertyInput
                            :style="settings.fg"
                              v-if="getPropertyTypes(profile[property]) === 'string'"
                              :label="getPropertyLabel(property)"
                              :property="property"
                              :profile="profile"
                              @inputEntered="inputEntered"
                            >
                            </StringPropertyInput>
                            <ObjectPropertyInput
                              v-if="getPropertyTypes(profile[property]) === 'object'"
                              :label="getPropertyLabel(property)"
                              :property="property"
                              :profile="profile"
                              @inputEntered="inputEntered"
                            >
                            </ObjectPropertyInput>
                            <DatePropertyInput
                              v-if="getPropertyTypes(profile[property]) === 'date'"
                              :label="getPropertyLabel(property)"
                              :property="property"
                              :profile="profile"
                              @inputEntered="inputEntered"
                            ></DatePropertyInput>
                          </div>
                        </div>
                      </ion-item>
                    </ion-list>
                  </ion-accordion>
                </ion-accordion-group>
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-4">
            <div class="card" :style="settings.fg">
              <div class="card-body">
                <ion-accordion-group multiple="true" expand="compact" class="mb-4" :style="settings.fg">
                  <ion-accordion
                  :style="settings.fg"
                    value="miscellaneous"
                    v-if="profile.languages || profile.hobbies"
                  >
                    <ion-item slot="header" :style="settings.fg">
                      <ion-label class=""><ion-icon :icon="listOutline"></ion-icon> Languages & Hobbies <br><br>
                        <small class="text-muted mt-5">French, english, programming...</small>
                      </ion-label>
                    </ion-item>
                    <ion-list slot="content" :style="settings.fg">
                      <ion-item lines="none" :style="settings.fg">
                        <div class="row">
                          <div
                            v-for="property in properties"
                            :key="property"
                            class="12"
                          >
                            <ArrayPropertyInput
                              v-if="getPropertyTypes(profile[property]) === 'array'"
                              :label="getPropertyLabel(property)"
                              :property="property"
                              :profile="profile"
                              @inputEntered="inputEntered"
                              @removeFromset="removeFromset"
                              @addOneToSet="addOneToSet"
                            ></ArrayPropertyInput>
                          </div>
                        </div>
                      </ion-item>
                    </ion-list>
                  </ion-accordion>
                </ion-accordion-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import router from "@/router";
import Avatar from "../../avatar/Avatar.vue";
import toaster from "../../../../../lib/toaster";
import { mapActions } from "vuex";
import { createNamespacedHelpers } from "vuex";
import {
  IonPage,
  IonContent,
  IonToolbar,
  IonHeader,
  IonAccordion,
  IonItem,
  IonList,
  IonIcon,
  IonLabel,
  IonAccordionGroup,
} from "@ionic/vue";
import { listOutline, personOutline } from "ionicons/icons";
import UpdateProfileBase from "../../profile/profile-update/UpdateProfileBase.vue";
import StringPropertyInput from "../../profile/profile-update/StringPropertyInput.vue";
import DatePropertyInput from "../../profile/profile-update/DatePropertyInput.vue";
import ArrayPropertyInput from "../../profile/profile-update/ArrayPropertyInput.vue";
import ObjectPropertyInput from "../../profile/profile-update/ObjectPropertyInput.vue";
const ProfileModule = createNamespacedHelpers("profile");
const SettingsModule = createNamespacedHelpers('setting')

export default {
  name: "Account",
  extends: UpdateProfileBase,
  components: {
    IonPage,
    IonContent,
    IonToolbar,
    IonHeader,
    Avatar,
    IonIcon,
    IonList,
    IonLabel,
    IonItem,
    IonAccordion,
    StringPropertyInput,
    IonAccordionGroup,
    DatePropertyInput,
    ArrayPropertyInput,
    ObjectPropertyInput,
  },
  data() {
    return {
      profile: {},
    };
  },
  setup() {
    return {
      listOutline,
      personOutline,
    };
  },
  computed: {
    ...ProfileModule.mapGetters(["selectProfile"]),
    ...SettingsModule.mapGetters(['selectUserSettings']),

    settings(){
      return this.selectUserSettings
    },
    properties() {
      return this.getProperties(this.selectProfile);
    },
  },
  methods: {
    ...mapActions(["changePageTitle"]),
    ...ProfileModule.mapActions(["updateProfile"]),

    addOneToSet({ property }) {
      this.profile[property].push("");
    },
    inputEntered(input) {
      this.profile[input.property] = input.value;
    },
    removeFromset(data) {
      const { index, property } = data;
      this.profile[property].splice(index, 1);
    },
    resetFields() {
      this.properties.forEach((property) => {
        if (this.getPropertyTypes(this.profile[property]) === "string") {
          this.profile[property] = "";
        } else if (this.getPropertyTypes(this.profile[property]) === "object") {
          this.profile[property] = {};
        } else if (this.getPropertyTypes(this.profile[property]) === "array") {
          this.profile[property] = [];
        }
      });
    },
    async setPageTitle() {
      await this.changePageTitle(router.currentRoute._value.name);
    },
    async savePersonal() {
      const properties = Object.getOwnPropertyNames(this.profile);
      const update = {};

      // update only valid properties
      properties.forEach((prop) => {
        if (this.profile[prop]) update[prop] = this.profile[prop];
      });

      const isValid = this.validateUpdate(update);

      if (isValid) {
        const response = await this.updateProfile(update);

        if (response && response.success) {
          toaster.success({
            position: "top",
            duration: 2000,
            message: "Profile updated successfully.",
          });
          this.resetFields();
          setTimeout(async () => {
            await router.push({ name: "Profile" });
          }, 2000);
        } else {
          toaster.error({
            position: "top",
            duration: 2000,
            message: "Profile update failed, please try again.",
          });
        }
      } else {
        toaster.info({
          position: "top",
          duration: 2000,
          message: "No need to update profile, no new information provided.",
        });
      }
    },
    async cancelPersonal() {
      this.resetFields();
    },
    validateUpdate(update) {
      return true;
    },
  },
  mounted() {
    this.profile = this.selectProfile;
    this.setPageTitle();
  },
};
</script>

<style scoped>
.avatar-title {
  margin-left: 0px;
}
.update-section .form-group {
  float: right;
}
.avatar-btn {
  margin-top: 55px;
  margin-left: 160px;
}
ion-icon {
  margin-right: 10px;
  font-size: 15px;
}
</style>